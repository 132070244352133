import merge from 'lodash/merge'

import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import defaultThemeConfig from '../../config/theme'

const darkTheme = responsiveFontSizes(
	createTheme(
		merge({}, defaultThemeConfig, {
			palette: {
				mode: 'dark',
				background: {
					default: '#1b1d1f',
					paper: '#202124',
				},
			},
		})
	)
)

export default darkTheme
