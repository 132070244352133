import merge from 'lodash/merge'

import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import defaultThemeConfig from '../../config/theme'
import shadows from './shadows'

const lightTheme = responsiveFontSizes(
	createTheme(
		merge({}, defaultThemeConfig, {
			palette: {
				background: {
					default: '#fff',
				},
			},
			shadows,
		})
	)
)

export default lightTheme
