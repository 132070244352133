import React, { useContext, useEffect, useReducer } from 'react'
import { globalHistory } from '@reach/router'
import axios from 'axios'
import { superSupplier as responseData } from '../mock-data'

const SearchContext = React.createContext()
const SearchDispatch = React.createContext()

const SEARCH_URL = /^\/supersupplier\/search\/?$/
const SUPERSUPPLIER_URL = /^\/supersupplier\/?$/

const emptyResult = {
	cDatatype: '',
	cIndustry: '',
	cRefine: '',
	cSearch: '',
	source: [],
	sourcecount: 0,
	sourceresultsettotal: 1,
	sourceresulttotal: 0,
	sourceset: 1,
	spellcheck: [],
	success: true,
}

const getQueryParam = (param, location) => {
	const query = location.search.substring(1)
	const params = query.split('&')
	for (let i = 0; i < params.length; i++) {
		const pair = params[i].split('=')
		if (decodeURIComponent(pair[0]) === param) {
			return decodeURIComponent(pair[1])
		}
	}
	return null
}

const fetchSearchResults = searchText => {
	if (!searchText) {
		return Promise.resolve(emptyResult)
	}

	if (process.env.NODE_ENV === 'development') {
		return new Promise(resolve => {
			setTimeout(() => resolve(responseData), 2500)
		})
	} else {
		return axios
			.post(
				'/supersupplier/api',
				{
					cSearch: searchText,
					nResultset: 1,
					cRefine: '',
					cDatatype: 'source',
				},
				{ headers: { 'Content-Type': 'multipart/form-data' } }
			)
			.then(response => response.data)
	}
}

const initialState = {
	data: [],
	error: '',
	isLoading: false,
	searchText: '',
	status: 'idle',
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_SEARCH_RESULTS':
			return {
				...state,
				searchText: action.payload,
				isLoading: true,
				status: 'loading',
			}
		case 'FETCH_SEARCH_RESULTS_SUCCESS':
			return {
				...state,
				data: action.payload,
				isLoading: false,
				status: 'success',
			}
		case 'FETCH_SEARCH_RESULTS_FAILED':
			return {
				...state,
				error: action.payload,
				isLoading: false,
				status: 'failed',
			}
		default:
			return state
	}
}

export const SearchProvider = ({ children, ...props }) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	useEffect(() => {
		const queryParam = getQueryParam('q', globalHistory.location)
		if (SEARCH_URL.test(globalHistory.location.pathname) && queryParam) {
			dispatch({ type: 'FETCH_SEARCH_RESULTS', payload: queryParam })
		}

		return globalHistory.listen(({ action, location }) => {
			const queryParam = getQueryParam('q', location)
			if (SEARCH_URL.test(location.pathname) && queryParam) {
				dispatch({ type: 'FETCH_SEARCH_RESULTS', payload: queryParam })
			} else if (SUPERSUPPLIER_URL.test(location.pathname)) {
				dispatch({ type: 'FETCH_SEARCH_RESULTS', payload: '' })
			}
		})
	}, [])

	useEffect(() => {
		if (!state.searchText) {
			return
		}
		fetchSearchResults(state.searchText)
			.then(results =>
				dispatch({
					type: 'FETCH_SEARCH_RESULTS_SUCCESS',
					payload: results ? results.source : [],
				})
			)
			.catch(err =>
				// TODO: log error
				dispatch({
					type: 'FETCH_SEARCH_RESULTS_FAILED',
					payload: err.message,
				})
			)
	}, [state.searchText])

	return (
		<SearchContext.Provider value={state}>
			<SearchDispatch.Provider value={dispatch}>
				{children}
			</SearchDispatch.Provider>
		</SearchContext.Provider>
	)
}

export const useSearchResults = () => {
	const state = useContext(SearchContext)
	if (!state) {
		throw new Error('useSearchResults must be used within a SearchProvider')
	}
	return state
}
