const blurCurve = (t) => t;
const distanceCurve = (t) => t;
const transparencyCurve = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

const layersOfShadow = 5;
const finalTransparency = 0.1;
const finalVerticalMultiplier = 4;
const finalBlurStrength = 40;

function createShadow(verticalDistance) {
    return Array.from({ length: layersOfShadow })
        .map((_, i) => {
            const alpha = transparencyCurve(1 / (i + 1)) * finalTransparency;
            const blur = blurCurve(1 / (i + 1)) * finalBlurStrength;
            const distance =
                distanceCurve(verticalDistance) * finalVerticalMultiplier;
            return `0 ${distance}px ${Math.round(
                blur
            )}px rgba(0, 0, 0, ${alpha})`;
        })
        .reverse()
        .join(',');
}

// Values from https://github.com/material-components/material-components-web/blob/be8747f94574669cb5e7add1a7c54fa41a89cec7/packages/mdc-elevation/_variables.scss
const shadows = [
    'none',
    createShadow(1),
    createShadow(2),
    createShadow(3),
    createShadow(4),
    createShadow(5),
    createShadow(6),
    createShadow(7),
    createShadow(8),
    createShadow(9),
    createShadow(10),
    createShadow(11),
    createShadow(12),
    createShadow(13),
    createShadow(14),
    createShadow(15),
    createShadow(16),
    createShadow(17),
    createShadow(18),
    createShadow(19),
    createShadow(20),
    createShadow(21),
    createShadow(22),
    createShadow(23),
    createShadow(24),
];

export default shadows;
