import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import {
	CssBaseline,
	Divider,
	GlobalStyles,
	Typography,
	//useMediaQuery,
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import { darkTheme /* lightTheme */ } from '../../src/themes'
import Link from '../../src/components/Link'

const components = {
	h1: props => <Typography gutterBottom variant={'h1'} {...props} />,
	h2: props => <Typography gutterBottom mt={4} variant={'h2'} {...props} />,
	h3: props => <Typography gutterBottom mt={4} variant={'h3'} {...props} />,
	h4: props => <Typography gutterBottom mt={4} variant={'h4'} {...props} />,
	h5: props => <Typography gutterBottom mt={4} variant={'h5'} {...props} />,
	h6: props => <Typography gutterBottom mt={4} variant={'h6'} {...props} />,
	p: props => <Typography paragraph variant={'body1'} {...props} />,
	hr: props => <Divider my={4} {...props} />,
	a: props => <Link {...props} />,
}

const TopLayout = ({ children }) => {
	// If we want to change theme based on user's browser theme, use this:
	// const isDark = useMediaQuery('(prefers-color-scheme: dark)')
	// const theme = isDark ? darkTheme : lightTheme

	const theme = darkTheme

	return (
		<ThemeProvider theme={theme}>
			<MDXProvider components={components}>
				<CssBaseline />
				<GlobalStyles styles={{}} />
				{children}
			</MDXProvider>
		</ThemeProvider>
	)
}

export default TopLayout
