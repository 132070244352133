exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-email-disclaimer-mdx": () => import("./../../../src/pages/email-disclaimer.mdx" /* webpackChunkName: "component---src-pages-email-disclaimer-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-legal-index-mdx": () => import("./../../../src/pages/legal/index.mdx" /* webpackChunkName: "component---src-pages-legal-index-mdx" */),
  "component---src-pages-legal-payment-terms-mdx": () => import("./../../../src/pages/legal/payment-terms.mdx" /* webpackChunkName: "component---src-pages-legal-payment-terms-mdx" */),
  "component---src-pages-legal-privacy-mdx": () => import("./../../../src/pages/legal/privacy.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-mdx" */),
  "component---src-pages-legal-sourcing-terms-and-conditions-mdx": () => import("./../../../src/pages/legal/sourcing-terms-and-conditions.mdx" /* webpackChunkName: "component---src-pages-legal-sourcing-terms-and-conditions-mdx" */),
  "component---src-pages-legal-terms-mdx": () => import("./../../../src/pages/legal/terms.mdx" /* webpackChunkName: "component---src-pages-legal-terms-mdx" */),
  "component---src-pages-missinglink-mdx": () => import("./../../../src/pages/missinglink.mdx" /* webpackChunkName: "component---src-pages-missinglink-mdx" */),
  "component---src-pages-profitrocket-mdx": () => import("./../../../src/pages/profitrocket.mdx" /* webpackChunkName: "component---src-pages-profitrocket-mdx" */),
  "component---src-pages-supersupplier-mdx": () => import("./../../../src/pages/supersupplier.mdx" /* webpackChunkName: "component---src-pages-supersupplier-mdx" */),
  "component---src-pages-supersupplier-search-mdx": () => import("./../../../src/pages/supersupplier/search.mdx" /* webpackChunkName: "component---src-pages-supersupplier-search-mdx" */),
  "component---src-pages-support-mdx": () => import("./../../../src/pages/support.mdx" /* webpackChunkName: "component---src-pages-support-mdx" */)
}

