const defaultFontFamily =
	'"Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

const palette = {
	primary: { main: '#4075ed' },
	secondary: { main: '#2F2D2E' },
	tertiary: { main: '#F5F5F5', contrastText: '#2F2D2E' },

	neutral: { main: '#000' },
	neutralVariant: { main: '#0a0a0a' },

	// TODO: These could be semantically better
	yellow: { main: '#F4E049' },
	green: { main: '#40ED83' },
	charcoal: { main: '#363537' },
	silver: { main: '#f8f8f8' },
	red: { main: '#db5440' },
	sky: { main: '#eef2fd' },

	text: { link: '#8abfff' },

	gradient: {
		default:
			'linear-gradient(45deg, #2e99cb 0%, #426acc 42%, #7250b5 100%)',
	},
}

const shape = {
	borderRadius: 24,
}

const typography = {
	fontFamily: defaultFontFamily,
	h1: { fontSize: '3.5625rem', fontWeight: 800 },
	h2: { fontSize: '2.8125rem', fontWeight: 800 },
	h3: { fontSize: '2.25rem', fontWeight: 800 },
	h4: { fontSize: '2rem', fontWeight: 700 },
	h5: { fontSize: '1.75rem', fontWeight: 700 },
	h6: { fontSize: '1.5rem', fontWeight: 700 },
	subtitle1: { fontSize: '1.375rem' },
	subtitle2: { fontSize: '1.25rem' },
	body1: { fontSize: '1.125rem' },
	body2: { fontSize: '1rem' },
	caption: { fontSize: '0.875rem', lineHeight: 1.7 },
}

const buttonHoverStyles = (color, variant) => ({
	'&:hover': {
		backgroundColor: variant === 'contained' ? color : 'transparent',
		borderColor: variant === 'outlined' ? 'transparent' : 'none',

		'&::after': {
			opacity: 1,
			transform: 'scale(1)',
		},
	},

	'&::after': {
		borderRadius: 9999,
		border: `2px solid ${color}`,
		content: '""',
		bottom: -6,
		left: -6,
		opacity: 0,
		position: 'absolute',
		right: -6,
		top: -6,
		transform: 'scale(0.925, 0.8)',
		transition: 'all 0.3s ease-in-out',
		zIndex: 1,
	},
})

const components = {
	MuiCssBaseline: {
		styleOverrides: `
			html {
				-webkit-font-smoothing: auto;
			}
		`,
	},
	MuiButton: {
		defaultProps: {
			disableElevation: true,
		},
		styleOverrides: {
			root: {
				borderRadius: shape.borderRadius * 10,
				fontWeight: 700,
				padding: '8px 24px',
				textTransform: 'capitalize',
				whiteSpace: 'nowrap',
			},
			contained: buttonHoverStyles(palette.primary.main, 'contained'),
			gradient: buttonHoverStyles(palette.primary.main, 'contained'),
			containedPrimary: {
				'&:hover': { backgroundColor: palette.primary.main },
				'&::after': { border: `2px solid ${palette.primary.main}` },
			},
			containedSecondary: {
				'&:hover': { backgroundColor: palette.secondary.main },
				'&::after': { border: `2px solid ${palette.secondary.main}` },
			},
			containedTertiary: {
				'&:hover': { backgroundColor: palette.tertiary.main },
				'&::after': { border: `2px solid ${palette.tertiary.main}` },
			},
			outlined: {
				'&, &:hover': {
					borderWidth: 2,
				},
			},
			outlinedTertiary: buttonHoverStyles(
				palette.tertiary.main,
				'outlined'
			),

			large: {
				padding: '12px 32px',
			},
		},
		variants: [
			{
				props: { size: 'large' },
				style: {
					padding: '12px 32px',
				},
			},
			{
				props: { size: 'x-large' },
				style: {
					fontSize: '1.125rem',
					padding: '24px 48px',
				},
			},
			{
				props: { variant: 'gradient' },
				style: {
					background: palette.gradient.default,
					color: 'white',
				},
			},
		],
	},
	MuiContainer: {
		defaultProps: {
			maxWidth: 'lg',
		},
	},
	MuiIcon: {
		defaultProps: {
			// Replace the `material-icons` default value.
			baseClassName: 'material-icons-round',
		},
	},
}

module.exports = {
	components,
	palette,
	shape,
	typography,
}
