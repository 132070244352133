import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import MuiLink from '@mui/material/Link'

import { transitionProps } from '../utils/transitions'
import { styled } from '@mui/material/styles'

const StyledMuiLink = styled(MuiLink)(({ theme }) => ({
	cursor: 'pointer',
	textDecoration: 'none',

	'&:hover': {
		color: theme.palette.primary.light,
		textDecoration: 'underline',
	},
}))

const Link = React.forwardRef(function Link({ to, ...props }, ref) {
	// This assumes that any internal link (intended for Gatsby) will start with
	// exactly one slash, and that anything else is external.
	const internal = /^\/(?!\/)/.test(to)

	return internal ? (
		<StyledMuiLink
			color={'text.link'}
			component={GatsbyLink}
			ref={ref}
			to={to}
			{...transitionProps}
			{...props}
		/>
	) : (
		<StyledMuiLink
			color={'text.link'}
			href={to}
			ref={ref}
			target={'_blank'}
			{...transitionProps}
			{...props}
		/>
	)
})

export default Link
